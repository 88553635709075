<template>
    <div>
        <a-card class="lf-card" size="small">
            
            <div slot="title">
                <span>生活照</span>
                <small class="text-minor ml-2">实体印刷报告将显示下面6张照片</small>
            </div>
            
            <a v-if="!readOnly" slot="extra" @click="activePicker">选择生活照</a>
            
            <div class="lf-list">
                <template v-for="(img,ik) in lifePhotos">
                    
                    <div class="lf-img" :key="ik" :style="`background-image:url(${img})`"
                         @click="cropImage(ik,img)">
                        
                        <div v-if="!readOnly" class="cropper-tips">剪裁照片</div>
                    
                    </div>
                
                </template>
                
                <template v-if="lifePhotos.length < 6 && !readOnly">
                    <div class="lf-uploader" @click="activeUploaderModal">
                        <a-icon type="upload"/>
                    </div>
                </template>
                
                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
            </div>
            
            <div class="text-minor">
                <small v-if="!readOnly">如照片显示不完整，请点击进行裁剪，以免影响印刷报告效果</small>
            </div>
        </a-card>
        
        <a-modal v-model="showPickerModal" title="选择生活照" @ok="saveLifePhotos">
            
            <div class="lf-list">
                
                <template v-for="(img,ik) in listPhotos">
                    
                    <div class="lf-img" :key="ik" :style="`background-image:url(${img})`" @click="selectImg(img)">
                        <span v-if="pickerList.indexOf(img) >= 0 " class="no">{{ pickerList.indexOf(img) + 1 }}</span>
                        <a @click.stop class="lf-link" :href="img" target="_blank">查看原图</a>
                    </div>
                
                </template>
                
                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
            </div>
        
        </a-modal>
        
        
        <image-cropper :aspect-ratio="114 / 149" :image="cropperImageSrc" v-model="showCropper"
                       @cropped="croppedImage"/>
        
        <a-modal width="300px" v-model="showUploaderModal" :footer="null">
            <div class="uploader-text">
                <img width="200" height="200" :src="qrcodeUrl" alt="">
                <p>管理员扫码协助用户上传生活照</p>
            </div>
        </a-modal>
    </div>
</template>

<script>
import ImageCropper from "@/components/ImageCropper.vue";

export default {
    name: "LifePhoto",
    components: {ImageCropper},
    props: {
        detail: Object,
        readOnly: Boolean
    },
    mounted() {
        this.loadInfo()
    },
    watch: {
        'detail.extra.life_photos'() {
            this.loadInfo()
        }
    },
    data() {
        return {
            showUploaderModal: false,
            showPickerModal: false,
            showCropper: false,
            lifePhotos: [],
            listPhotos: [],
            pickerList: [],
            cropperImageSrc: "",
        }
    },
    computed: {
        qrcodeUrl() {
            return this.$qrcode(`/packagesB/lifePhotos/lifePhotos?id=${this.detail.am_id}`, `${this.detail.am_id}-up-life-photo.png`)
        }
    },
    methods: {
        activeUploaderModal() {
            this.showUploaderModal = true
        },
        activePicker() {
            this.showPickerModal = true
            this.pickerList = []
        },
        selectImg(img) {
            
            let idx = this.pickerList.indexOf(img)
            
            if (idx < 0) {
                if (this.pickerList.length >= 6) return;
                
                return this.pickerList.push(img)
            }
            
            this.pickerList.splice(idx, 1)
        },
        cropImage(ik, img) {
            
            if (this.readOnly) return window.open(img);
            
            this.cropperImageSrc = img
            this.showCropper = true
            this.cropperImageIdx = ik
        },
        loadInfo() {
            
            let list = this.detail.extra.life_photos;
            
            if (!list) list = this.detail.member_image.life_photos;
            
            if (!list) list = [];
            
            this.lifePhotos = list.slice(0, 6)
        },
        croppedImage(dta) {
            let photos = JSON.parse(JSON.stringify(this.lifePhotos))
            
            photos.splice(this.cropperImageIdx, 1, dta)
            
            this.pickerList = photos;
            
            this.showCropperModal = false
            
            this.saveLifePhotos()
        },
        saveLifePhotos() {
            this.showPickerModal = false
            
            this.$emit('change', [
                'life_photos', this.pickerList
            ])
        }
    }
}
</script>

<style scoped lang="less">
.lf-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
}

.lf-img {
    width: 114px;
    height: 149px;
    
    img {
        width: 1px;
        height: 1px;
        opacity: 0;
    }
    
    margin-bottom: 15px;
    
    background-color: black;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    
    margin-right: 15px;
    display: block;
    position: relative;
    cursor: pointer;
    
    .no {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 20px;
        height: 20px;
        background-color: black;
    }
    
    &.empty {
        height: 0;
    }
    
    .lf-link {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #dddddd;
        padding: 2px 5px;
        opacity: 0;
    }
    
    &:hover .lf-link {
        opacity: 1;
    }
}

.lf-img:hover .cropper-tips {
    opacity: 1;
}

.lf-uploader {
    width: 114px;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    border: 1px solid #dddddd;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.uploader-text {
    font-size: small;
    text-align: center;
    
    img {
        margin-bottom: 10px;
    }
}

.cropper-tips {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(221, 221, 221, 0.42);
    color: white;
    opacity: 0;
}

</style>